
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/service/user.service';
import { ConfirmPasswordValidator } from 'app/validator/confirm-password.validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm!: FormGroup;
  submitted!: boolean;
  password: any;
  new_password: any;
  errormsg: any;
  selectedLanguage:any = "en";
  confirmPassword: boolean = false;
  newPassword: boolean = false;
  supportedLanguages: { flag: string,code: string, name: string}[] = [
    { flag: '../../../assets/english-flag.png',code: 'en' , name: 'English' },
    { flag: '../../../assets/german-flag.png' ,code: 'de', name: 'German', },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr',name: 'French', }
  ];
  constructor(private http: HttpClient, public _router: Router,
    private userService: UserService,
    private _translate: TranslateService,
    private toastr: ToastrService, private translate: TranslateService, private formBuilder: FormBuilder,) {
      translate.setDefaultLang('en');
      const savedLanguage = localStorage.getItem('selectedLanguage');
      if (savedLanguage) {
        this.selectedLanguage = savedLanguage;
        translate.use(savedLanguage);
      } else {
        translate.use('en');
      }
  }

 
  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group(
      {
        password: [null, [Validators.required, Validators.minLength(6)]],
        new_password: [null, [Validators.required, Validators.minLength(6)]],
      },
      {
        validator: ConfirmPasswordValidator('password', 'new_password'),
      }
    );
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }

  newPasswordVisibility() {
    this.newPassword = !this.newPassword;
  }

  confirmPasswordVisibility() {
    this.confirmPassword = !this.confirmPassword;
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
   
  }

  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls
  }

  changePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    const token = this.extractTokenFromURL();
    let data = {
      new_password: this.changePasswordForm.controls['new_password'].value,
      token: token,
    };
    if (this.changePasswordForm.value.password === this.changePasswordForm.value.new_password) {
      this.userService.changePassword(data).subscribe(
        (res) => {
          this._router.navigate(['/login']);
          this.toastr.success('Change-Password successfully');
        },
        (err) => {
          if (err.status == 409) {
            this.errormsg = 'changePassword.form.error.passwordIsNotNew';
            this.toastr.error('Invalid Password!');
          } else {
            this.errormsg = 'changePassword.form.error.passwordNotMatch';
            this.toastr.error('Invalid Password!');
          }
        }
      );
    }
  }

private extractTokenFromURL(): string | null {
  const url = window.location.href;
  const tokenMatch = url.match(/[?&]token=([^&]+)/);
  return tokenMatch ? decodeURIComponent(tokenMatch[1]) : null;
}

}
