<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
  rel="stylesheet"
/>
<div class="inner-wrapperlogin">
  <div class="frontscreen-block">
    <div class="loging-header d-flex justify-content-between px-3">
      <div class="position-relative language-btn-block"></div>
      <div class="d-flex justify-content-between">
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <img
                [src]="getLanguageFlag(selectedLanguage)"
                class="selected-flag"
              />
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <span><img [src]="language.flag" /></span>
                {{ language.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 vh-100 align-items-center d-flex rounded-3">
          <div class="rightScreen login-card">
            <div class="logo-block">
              <a
                class="navbar-brand text-primary fw-bold fs-1"
                href="javascript:void(0)"
              >
                <img src="../../../assets/nynos-logo.svg" />
              </a>
              <h2 class="fs-2 fw-bold">{{ "signup.signUp" | translate }}</h2>
              <h6>{{ "signup.signUpconnected" | translate }}</h6>
            </div>
            <form [formGroup]="signupForm" class="row" (ngSubmit)="signUp()">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-3 col">
                    <label>{{ "form.firstname" | translate }}</label>
                    <div class="form-block">
                      <input
                        type="text"
                        class="form-control"
                        id="fname"
                        placeholder="{{ 'form.firstname' | translate }}"
                        formControlName="first_name"
                      />
                      <div
                        *ngIf="
                          (submitted || f['first_name'].touched) &&
                          f['first_name'].hasError('required')
                        "
                        class="invalid-feedback"
                      >
                        {{ "signup.firstName" | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3 col">
                    <label>{{ "form.lastname" | translate }}</label>
                    <div class="form-block">
                      <input
                        type="text"
                        class="form-control"
                        id="lname"
                        placeholder="{{ 'form.lastname' | translate }}"
                        formControlName="last_name"
                      />
                      <div
                        *ngIf="
                          (submitted || f['last_name'].touched) &&
                          f['last_name'].hasError('required')
                        "
                        class="invalid-feedback"
                      >
                        {{ "signup.LastName" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <label>{{ "form.company" | translate }}</label>
                <div class="form-block">
                  <input
                    type="text"
                    class="form-control"
                    id="company"
                    placeholder="{{ 'form.company' | translate }}"
                    formControlName="company"
                  />
                  <div
                    *ngIf="
                      (submitted || f['company'].touched) &&
                      f['company'].hasError('required')
                    "
                    class="invalid-feedback"
                  >
                    {{ "signup.Company" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label>{{ "form.email" | translate }}</label>
                <div class="form-block">
                  <input
                    type="mail"
                    class="form-control"
                    id="mail"
                    placeholder="{{ 'form.email' | translate }}"
                    formControlName="email"
                  />
                  <div
                    *ngIf="
                      (submitted || f['email'].touched) &&
                      f['email'].hasError('required')
                    "
                    class="invalid-feedback"
                  >
                    {{ "signup.Email" | translate }}
                  </div>
                  <div
                    *ngIf="submitted && f['email'].hasError('email')"
                    class="invalid-feedback"
                  >
                    {{ "signup.valid" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label>{{ "signup.form.why" | translate }}</label>
                <div class="form-block">
                  <textarea
                    type="text"
                    class="form-control"
                    formControlName="additional_info"
                  ></textarea>
                  <div
                    *ngIf="
                      (submitted || f['additional_info'].touched) &&
                      f['additional_info'].hasError('required')
                    "
                    class="invalid-feedback"
                  >
                    {{ "signup.form.email.error.required" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div
                  class="d-flex justify-content-between flex-lg-row flex-column"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      formControlName="acceptTerms"
                      id="termsaccept"
                      name="terms"
                      required
                    />
                    <label
                      class="form-check-label text-black-50"
                      for="termsaccept"
                    >
                      {{ "signup.accepting" | translate }}</label
                    >
                    <div
                      *ngIf="
                        (submitted || f['acceptTerms'].touched) &&
                        f['acceptTerms'].hasError('required')
                      "
                      class="invalid-feedback"
                    >
                      {{ "signup.acceptTerms" | translate }}
                    </div>
                  </div>

                  <div class="form-check">
                    <label class="form-check-label">
                      <a class="text-decoration-none">{{
                        "signup.conditions" | translate
                      }}</a></label
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <hr />
                <button type="submit" class="btn">
                  {{ "signup.form.submit" | translate }}
                </button>
                <hr />
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100">
          <div class="leftScreen"></div>
        </div>
      </div>
    </div>
  </div>
</div>
