import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AcceptComponent } from './authentication/accept/accept.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserService } from './service/user.service';
import { BootstrapService } from './service/bootstrap.service';
import { AuthInterceptor } from './auth.interceptor';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { LoadingComponent } from './loader/loading/loading.component';
import { CommonService } from './service/common.service';
import { AuthGuard } from './auth.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PasswordStrengthComponent } from './authentication/password-strength/password-strength.component';
import { LineChartModalComponent } from './pages/charts/line-chart-modal/line-chart-modal.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    LoadingComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    AcceptComponent,
    ChangePasswordComponent,
    PasswordStrengthComponent,
    LineChartModalComponent
  ],
  imports: [
    NgbModule,
    ModalModule,
    BrowserModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ComponentsModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [UserService,BootstrapService , CommonService, BsModalService,{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
],
bootstrap: [AppComponent],
schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
