<section class="mainFooter">
    <div class="container-fluid">
      <div class="mainFooter__text">
        <p>© 2024 Nynos GmbH - All rights reserved</p>
      </div>
      <div class="mainFooter__logo">
        <img [src]="'../../../assets/nynos-logo.svg'" alt="Nynos Logo" />
      </div>
    </div>
  </section>
  