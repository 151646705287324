import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from 'app/service/bootstrap.service';
import { UserService } from 'app/service/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss']
})
export class AcceptComponent implements OnInit {
  selectedLanguage: any = 'en';
  Createacceptform: FormGroup;
  visible: boolean = false;
  IsVisible: boolean = false;
  ERROR_PASSWORD_NOT_MATCH = 'form.passwordNotMatch';
  uid: any;
  user: any;
  password: any;
  invitationIsExpired: boolean = true;
  closeResult: string;
  isreadMode: boolean = false;
  newPassword: boolean = false;
  repeatPassword: boolean = false;

  supportedLanguages: { flag: string, code: string, name: string }[] = [
    { flag: '../../../assets/english-flag.png', code: 'en', name: 'English' },
    { flag: '../../../assets/german-flag.png', code: 'de', name: 'German' },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr', name: 'French' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService
  ) {
    translate.setDefaultLang('en');
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      translate.use(savedLanguage);
    } else {
      translate.use('en');
    }
  }
  ngOnInit() {
    this.createAccept();
    this.getMe();
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams['first_name'] && queryParams['last_name'] && queryParams['email'] && queryParams['uuid']) {
      const uuidParam = queryParams['uuid'];
      const uuids = uuidParam.split('?uuid=');
      const lastUuid = uuids.length > 1 ? uuids[1] : uuids[0];

      this.Createacceptform.patchValue({
        firstName: queryParams['first_name'],
        lastName: queryParams['last_name'],
        email: queryParams['email'],
        uuid: lastUuid,
      });
    }

    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }


  createAccept() {
    this.Createacceptform = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$')]],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      password: [null, Validators.required],
      repeatpass: [null, Validators.required],
      uuid: [null]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.Createacceptform.controls;
  }

  togglePasswordVisibility() {
    this.newPassword = !this.newPassword;
  }

  repeatPasswordVisibility() {
    this.repeatPassword = !this.repeatPassword;
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
  }


  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }

  getMe() {
    this.userService.getMe().subscribe((res: any) => {
      this.user = res;
      this.user.role = res.role;
      this.uid = res.company;
    });
  }

  getInvitation() {
    this.userService.getInvitation(this.uid).subscribe(res => {
      this.invitationIsExpired = false;
      this.user = res;
    }, (error) => {
      this.invitationIsExpired = true;
    })

  }

  join() {
    if (this.Createacceptform && this.Createacceptform.controls['uuid']) {
      if (this.Createacceptform.controls['password'].value === this.Createacceptform.controls['repeatpass'].value) {
        let newData = {
          password: this.Createacceptform.controls['password'].value,
        };

        const uuid = this.Createacceptform.controls['uuid'].value;

        this.userService.acceptInvitation(uuid, newData).subscribe(
          res => {
            this.router.navigate(['/login']);
            this.toastr.success('Accepting invitation successfully');
          },
          error => {
            this.toastr.error('Not Accepting invitation');
            console.error('Error accepting invitation:', error);
          }
        );
      } else {
        this.toastr.error('Password mismatch');
      }
    } else {
      console.error('Form control or UUID is undefined');
      this.toastr.error('Form control is not properly initialized');
    }
  }



}
