<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
  rel="stylesheet"
/>
<div class="inner-wrapperlogin">
  <div class="frontscreen-block">
    <div class="loging-header d-flex justify-content-between px-3">
      <div class="position-relative language-btn-block"></div>
      <div class="d-flex justify-content-between">
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <img [src]="getLanguageFlag(selectedLanguage)" class="selected-flag" />
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <span><img [src]="language.flag" /></span>
                {{ language.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 vh-100 align-items-center d-flex rounded-3">
          <div class="rightScreen login-card">
            <div class="logo-block pb-5">
              <a class="navbar-brand text-primary fw-bold fs-1"  href="javascript:void(0)">
                <img src="../../../assets/nynos-logo.svg" />
              </a>
              <h2 class="fs-2 fw-bold">{{"resetPassword.callback.submit" | translate}}</h2>
            </div>
            <form class="row mt-5" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()"> 
              <div class="col-md-12 mb-3">
                <label>{{"resetPassword.callback.password"|translate}}</label>
                <div class="form-block">
                  <input
                  type="{{ newPassword ? 'text' : 'password' }}"
                    type="Password"
                    class="form-control"
                    id="Password"
                    placeholder="{{'resetPassword.callback.password'|translate}}"
                    formControlName="password"
                  />
                  <button type="button" class="btn btn-toggle" (click)="newPasswordVisibility()">
                    <img [src]="newPassword ? '../../../assets/eye-slash.svg' : '../../../assets/eye.svg'" alt="Toggle Password Visibility" />
                </button>
              </div>
              <div *ngIf="(submitted || f['password'].touched) && f['password'].hasError('required')" class="invalid-feedback">
                  {{"acceptform.form.pass.error.required" | translate}}
              </div>
              <label class="mt-2">
                <app-password-strength [passwordToCheck]="changePasswordForm.value.password"></app-password-strength>
              </label>
             
              </div>
              <div class="col-md-12 mb-3">
                <label>{{"resetPassword.callback.passwordRepeat" | translate}}</label>
                <div class="form-block">
                  <input
                  type="{{ confirmPassword ? 'text' : 'password' }}"
                    type="Password"
                    class="form-control"
                    id="new password"
                    placeholder="{{'resetPassword.callback.passwordRepeat' | translate}}"
                    formControlName="new_password"
                  />
                  <button type="button" class="btn btn-toggle" (click)="confirmPasswordVisibility()">
                    <img [src]="confirmPassword ? '../../../assets/eye-slash.svg' : '../../../assets/eye.svg'" alt="Toggle Password Visibility" />
                </button>
              </div>
                  <div *ngIf="
                  (submitted || f['new_password'].touched) &&
                  f['new_password'].hasError('required')
              " class="invalid-feedback">
                 {{"acceptform.form.repeatpass.error.required" | translate}}
                </div>
               
                
               
              </div>
              <ng-container
              class="form-group form-message error-message"
              role="alert"
              show="err"
              *ngIf="errormsg"
            >
              <div class="form-error"  *ngIf="errormsg && !changePasswordForm.invalid">
                <span style="color: red"> {{ errormsg | translate }} </span>
              </div>
            </ng-container>
              <div class="col-12 text-center">
                <hr />
                <button type="submit" class="btn">{{"resetPassword.callback.submit"| translate}}</button>
                <hr />
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100">
          <div class="leftScreen"></div>
        </div>
      </div>
    </div>
  </div>
</div>
