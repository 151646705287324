import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from 'app/service/bootstrap.service';
import { UserService } from 'app/service/user.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm!: FormGroup;
  submitted!: boolean;
  user: any;
  userRole: any;
  showPassword: boolean = false;
  errormsg: any;
  candidateId: any;
  selectedLanguage: any = "en";
  isLoading: boolean = false;
  companyId: any;
  supportedLanguages: { flag: string, code: string, name: string }[] = [
    { flag: '../../../assets/english-flag.png', code: 'en', name: 'English' },
    { flag: '../../../assets/german-flag.png', code: 'de', name: 'German', },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr', name: 'French', }
  ];
  constructor(private http: HttpClient, public router: Router, private formBuilder: FormBuilder,
    private userService: UserService,
    private translate: TranslateService,
    private bootstrapService: BootstrapService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService) {
    translate.setDefaultLang('en');
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      translate.use(savedLanguage);
    } else {
      translate.use('en');
    }
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          this.noWhitespaceValidator,
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.noWhitespaceValidator,
        ],
      ],
    });
    //this.getMe()
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
  }

  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let data = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.userService.login(data).subscribe(
      (response) => {
        this.cookieService.set("authToken", response.accessToken);
        this.getMe();
        this.bootstrapService.bootstrap();
        return this.toastr.success('Login successfully', 'Success', { timeOut: 2000 });


      },
      (error) => {
        // Handle login error
        if (error.status == 400) {
          this.errormsg = 'login.form.error.badcredentials';
          this.toastr.error(this.translate.instant('login.form.error.badcredentials'));
        } else if (error.status == 403) {
          this.userService.logout();
          this.errormsg = 'login.form.error.tokenExpired';
          this.toastr.error(this.translate.instant('login.form.error.tokenExpired'));
        } else {
          this.errormsg = 'login.form.error.generic';
          this.toastr.error(this.translate.instant('login.form.error.generic'));
        }
      }
    );
  }


  getMe() {
    this.userService.getMe().subscribe((res: any) => {
      this.user = res;
      this.userRole = res.role;
      this.candidateId = res.uuid;
      this.companyId = res.company;
      this.cookieService.set("userRole", this.userRole);
      localStorage.setItem("candidateId", this.candidateId);
      localStorage.setItem("companyId", this.companyId);
    });
  }

}
