import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, catchError, map, throwError } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class BootstrapService {
  user: any;
  ROLE_MANAGER = 'MANAGER';
  ROLE_EMPLOYEE = 'EMPLOYEE';
  ROLE_APPLICANT = 'APPLICANT';
  ROLE_ADMIN = 'ADMIN';
  ROLE_TRAINEE = 'TRAINEE';
  userRole: any;
  constructor(private http: HttpClient, public router: Router, private userService: UserService,) { }


bootstrap() {
  this.userService.getMe().subscribe((res: any) => {
    this.user = res;
    if (this.user.role === 'MANAGER') {
      this.router.navigate(['/invitations']);
      return 'MANAGER';
    } else if (this.user.role === 'ADMIN') {
      this.router.navigate(['/admin']);
      return 'ADMIN';
    } else if (this.user.role === 'EMPLOYEE') {
      this.router.navigate(['/profile']);
      return 'EMPLOYEE';
    }  else if (this.user.role === 'APPLICANT') {
      this.router.navigate(['/profile']);
      return 'APPLICANT';
    } else if (this.user.role === 'TRAINEE') {
      this.router.navigate(['/profile']);
      return 'TRAINEE';
    } else {
      this.router.navigate(['/profile']);
      return 'EMPLOYEE';
    }
  });
}

}





