import { HttpClient } from '@angular/common/http';
import {  Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/service/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  selectedLanguage: any = "en"
  submitted!: boolean;
  signupForm: FormGroup;
  isLoading: boolean = false;
  supportedLanguages: { flag: string,code: string, name: string}[] = [
    { flag: '../../../assets/english-flag.png',code: 'en' , name: 'English' },
    { flag: '../../../assets/german-flag.png' ,code: 'de', name: 'German', },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr',name: 'French', }
  ];
  constructor(private http: HttpClient, public router: Router, private formBuilder: FormBuilder,
    private userService: UserService,
    private translate: TranslateService,
    private toastr: ToastrService,) {
      translate.setDefaultLang('en');
      const savedLanguage = localStorage.getItem('selectedLanguage');
      if (savedLanguage) {
        this.selectedLanguage = savedLanguage;
        translate.use(savedLanguage);
      } else {
        translate.use('en');
      }
  }

  ngOnInit(): void {
    this.signupForm = this.formBuilder.group({
      first_name: [
        null,
        Validators.required,

      ],
      last_name: [null, Validators.required],
      company: [null, Validators.required],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          this.noWhitespaceValidator,
          Validators.pattern('^[aA-zZ0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      additional_info: [null],
      acceptTerms: [null, Validators.required]
    });

    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.signupForm.controls
  }

  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
   
  }

  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }


  signUp() {
    this.submitted = true;
    if (this.signupForm.invalid) {
      return;
    }
    let data = {
      first_name: this.signupForm.controls['first_name'].value,
      last_name: this.signupForm.controls['last_name'].value,
      company: this.signupForm.controls['company'].value,
      email: this.signupForm.controls['email'].value,
      additional_info: this.signupForm.controls['additional_info'].value,
      acceptTerms: this.signupForm.controls['acceptTerms'].value
    };
    this.isLoading = true;
    this.userService.signUp(data).subscribe((res) => {
      this.router.navigate(['/login']);
      this.isLoading = false;
      this.toastr.success('User added successfully', 'Success', { timeOut: 2000 });
    });
  }
}