<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
  rel="stylesheet"
/>
<div class="inner-wrapperlogin">
  <div class="frontscreen-block">
    <div class="loging-header d-flex justify-content-between px-3">
      <div class="position-relative language-btn-block"></div>
      <div class="d-flex justify-content-between">
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <img
                [src]="getLanguageFlag(selectedLanguage)"
                class="selected-flag"
              />
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <span><img [src]="language.flag" /></span>
                {{ language.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 vh-100 align-items-center d-flex rounded-3">
          <div class="rightScreen login-card">
            <div class="logo-block">
              <a class="navbar-brand text-primary fw-bold fs-1" href="#">
                <img src="../../../assets/nynos-logo.svg" />
              </a>
              <h2 class="fs-2 fw-bold">{{ "login.signin" | translate }}</h2>
              <h6>{{ "login.stayConnected" | translate }}</h6>
            </div>
            <form [formGroup]="loginForm" class="row" (ngSubmit)="login()">
              <div class="col-md-12 mb-3">
                <label>{{ "form.email" | translate }}</label>
                <div class="form-block">
                  <input
                    type="mail"
                    class="form-control"
                    id="mail"
                    placeholder="{{ 'form.email' | translate }}"
                    formControlName="email"
                  />
                  <div
                    *ngIf="
                      (submitted || f['email'].touched) &&
                      f['email'].hasError('required')
                    "
                    class="invalid-feedback"
                  >
                    {{ "login.form.email.error.required" | translate }}
                  </div>
                  <div
                    *ngIf="submitted && f['email'].hasError('email')"
                    class="invalid-feedback"
                  >
                    {{ "login.form.email.error.email" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label>{{ "form.password" | translate }}</label>
                <div class="form-block">
                    <input  type="{{ showPassword ? 'text' : 'password' }}" class="form-control" id="password"
                        placeholder="{{
                                      'form.password' | translate
                                    }}" formControlName="password" />
                       <button type="button" class="btn btn-toggle" (click)="togglePasswordVisibility()">
                        <img [src]="showPassword ? '../../../assets/eye-slash.svg' : '../../../assets/eye.svg'" alt="Toggle Password Visibility" />
                    </button>
                    <div *ngIf="(submitted || f['password'].touched) && f['password'].hasError('required')" class="invalid-feedback">
                        {{ "login.form.password.error.required" | translate }}
                    </div>
                    <div *ngIf="submitted && f['password'].hasError('minlength')" class="invalid-feedback">
                        {{ "login.form.password.error.pass" | translate }}
                    </div>
                </div>
                <ng-container *ngIf="errormsg">
                  <p class="invalid-feedback" *ngIf="errormsg && !loginForm.get('password')?.errors?.['required']">
                      {{ errormsg | translate}}
                  </p>
              </ng-container>
            </div>
            
              <div class="col-md-12 mb-3">
                <div
                  class="d-flex justify-content-between flex-lg-row flex-column"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    <label
                      class="form-check-label text-black-50"
                      for="flexCheckDefault"
                    >
                      {{ "login.form.remember" | translate }}</label
                    >
                  </div>
                  <div class="form-check">
                    <label class="form-check-label">
                      <a
                        class="text-decoration-none"
                        [routerLink]="['/reset-password']"
                        >{{ "login.form.forgotPassword" | translate }}</a
                      ></label
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <hr />
                <button type="submit" class="btn">
                  {{ "login.form.submit" | translate }}
                </button>
                <hr />
              </div>
              <div class="col-12">
                <div class="mt-3 text-center">
                  {{ "login.form.noAccount" | translate }}
                  <a
                    [routerLink]="['/register']"
                    class="text-decoration-none"
                    >{{ "login.signUp" | translate }}</a
                  >
                </div>
              </div>
              <div class="col-12">
                <div class="social-icon">
                  <a href=""><i class="fa fa-google" aria-hidden="true"></i></a>
                  <a href=""
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                  <a href=""
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                  <a href=""
                    ><i class="fa fa-linkedin" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
              <div class="col-12">
                <div class="text-center">
                  {{ "login.signInWithoutNynos" | translate }}
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100">
          <div class="leftScreen"></div>
        </div>
      </div>
    </div>
  </div>
</div>
