<div class="wrapper">
  <div class="sidebar" data-color="danger">
    <app-navbar (click)="toggleSidebar()"></app-navbar>
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel">
    <div class="left-sidebar"></div>
    <div class="main-content-block">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
    
  </div>
</div>
