<nav
  class="navbar navbar-expand-lg fixed-top navbar-dark bg-white py-1 py-lg-3"
>
  <div class="container-fluid">
    <a class="navbar-brand text-primary fw-bold fs-1 update-logo-button" href="javascript:void(0)">
      <img  [src]="logoSrc || '../../../assets/nynos-logo.svg'" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="" *ngIf="isManager || isAdmin">
          <a
            routerLinkActive="active"
            [routerLink]="isManager ? ['/invitations'] : ['/admin']"
            >{{ "menu.dashboard" | translate }}</a
          >
        </li>

        <li *ngIf="isManager || isAdmin || isEmployee || isApplicant || isTrainee">
          <a routerLinkActive="active"  [routerLink]="['/profile']">{{
            "menu.profile" | translate
          }}</a>
        </li>
         <li *ngIf="isManager || isEmployee || isApplicant || isTrainee">
          <a routerLinkActive="active" [routerLink]="['/questionary']">{{
            "menu.questionary" | translate
          }}</a>
        </li>
        <li *ngIf="isManager || isAdmin ">
          <a routerLinkActive="active" [routerLink]="isManager ? ['/progress'] : ['/users']">{{
            "menu.users" | translate
          }}</a>
        </li>
        <li
          *ngIf="
            isManager || isAdmin || (isApplicant && questionary === 'DONE')
          "
        >
          <a routerLinkActive="active" [routerLink]="['/report']">{{
            "menu.report" | translate
          }}</a>
        </li>
      </ul>
      <div class="d-flex gap-3">
        
        <div class="btn-group avatar-header-block cursor-pointer">
          <a
           class=""
            id="dropdownMenuClickableInside"
            data-bs-toggle="dropdown"
            data-bs-auto-close="inside-outside"
            aria-expanded="false"
          >
          <img src="../../../assets/avatar-icon.png">
        </a>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuClickableInside"
          >
            <li  *ngIf="isManager">
              <a

                class="dropdown-item"
                href="javascript:void(0)"
               
                (click)="companyDetail()"
                ><i class="fa fa-building "></i> {{
            "menu.companyDetails" | translate
          }}
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                data-bs-toggle="modal" data-bs-target="#userModal"
                > <i class="fa fa-sign-out "></i> {{
                  "menu.logouts" | translate
                }}</a
              >
            </li>
          </ul>
        </div>
      
    
        
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              [src]="getLanguageFlag(selectedLanguage)"
              class="selected-flag"
            />
            <span>
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <img [src]="language.flag" />
                <span>
                  {{ language.name }}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Modal account-->
<div
  class="modal fade"
  id="userModal"
  tabindex="-1"
  aria-labelledby="ModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-6" id="ModalLabel">
          <strong>{{ "report.user" | translate }}</strong>
        </h1>
        <button
          type="button"
          class="btn-close-modal"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="../../../assets/close-icon-white.svg" />
        </button>
      </div>
      <div class="modal-body">
        <div class="card px-0 mb-0">
          <div class="card-body p-0">
            <div class="company-item">
              <strong>{{ "menu.logout" | translate }}</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary px-4 d-flex align-items-center gap-2 border-0"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
        {{ "form.Cancel" | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary px-4 d-flex align-items-center gap-2 border-0"
          (click)="logOut()"
        >
        {{ "form.ok" | translate }}

        </button>
      </div>
    </div>
  </div>
</div>
