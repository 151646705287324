import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BootstrapService } from 'app/service/bootstrap.service';
import { UserService } from 'app/service/user.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  searchText: any;
  toggle = true;
  status = 'Enable';
  isAdmin = true;
  isManager = true;
  isEmployee = true;
  isApplicant = true;
  isTrainee = true;
  selectedLanguage: any = 'en';
  currentTheme: any = 'dark';
  newValue: string = 'Initial Value';
  roleBased: any;
  questionary: any;
  showReportMenu: boolean = false;
  logoSrc: any;
  supportedLanguages: { flag: string, code: string, name: string }[] = [
    { flag: '../../../assets/english-flag.png', code: 'en', name: 'English' },
    { flag: '../../../assets/german-flag.png', code: 'de', name: 'German', },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr', name: 'French', }
  ];
  constructor(
    private translate: TranslateService,
    public router: Router,
    private userService: UserService,
    private renderer: Renderer2,
    private el: ElementRef,
    private bootstrapService: BootstrapService,
    private toastr: ToastrService,
    public cookieService: CookieService,
  ) {
    translate.setDefaultLang('en');
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedLanguage = savedLanguage;
      translate.use(savedLanguage);
    } else {
      translate.use('en');
    }
    this.renderer.addClass(
      this.el.nativeElement.ownerDocument.body,
      'dark-theme-selector'
    );

  }

  ngOnInit() {
    this.getRole();
    this.getUserQuestionaries();
    this.getCompanyLogo()
  }
  getCompanyLogo() {
    const companyId = localStorage.getItem('companyId');
    if (companyId) {
      this.userService.getOrganization(companyId).subscribe((res: any) => {
        this.logoSrc = res.company_logo;
      });
    }
  }


  companyDetail() {
    this.router.navigate(['/company-detail']);
  }
  getRole() {
    this.roleBased = this.cookieService.get('userRole');
    if (this.roleBased == 'MANAGER') {
      this.isManager = true;
      this.isAdmin = false;
      this.isEmployee = false;
      this.isApplicant = false;
      this.isTrainee = false;
    } else if (this.roleBased == 'ADMIN') {
      this.isAdmin = true;
      this.isManager = false;
      this.isEmployee = false;
      this.isApplicant = false;
      this.isTrainee = false;
    } else if (this.roleBased == 'EMPLOYEE') {
      this.isEmployee = true;
      this.isManager = false;
      this.isAdmin = false;
      this.isApplicant = false;
      this.isTrainee = false;
    } else if (this.roleBased == 'APPLICANT') {
      this.isEmployee = false;
      this.isManager = false;
      this.isAdmin = false;
      this.isApplicant = true;
      this.isTrainee = false;
    } else if (this.roleBased == 'TRAINEE') {
      this.isEmployee = false;
      this.isManager = false;
      this.isAdmin = false;
      this.isApplicant = false;
      this.isTrainee = true;
    }
    this.getUserQuestionaries();
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }


  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
    let lang1 = localStorage.getItem('selectedLanguage');
    let userItem = localStorage.getItem('newDep');
    let candidateItem = localStorage.getItem('defultCandidate')
    let uId = localStorage.getItem('userId')
    let OrgId = localStorage.getItem('organizationId')
    let newUser = localStorage.getItem('selectedReport')
    let useruid = localStorage.getItem('useruuId')
    if (this.router.url === '/questionary') {
      this.userService.getUserQuestionaries(uId).subscribe((res) => {
        this.userService.updateValues(res, lang1);
      })
    } else if (this.router.url === '/report') {
      this.userService.getCorridorReport(OrgId, useruid, userItem).subscribe((res) => {
        this.userService.updateCorridor(res, lang1);
      })
      this.userService.getCorridorCandidate(OrgId, candidateItem).subscribe((res) => {
        this.userService.updateCandidate(res, lang1);

      })
      location.reload()
    } else if (this.router.url === '/report/user-detail') {
      this.userService.getReportDetails(newUser).subscribe((res) => {
        this.userService.updateGenerate(res, lang1);
        location.reload();
      })
    }
  }

  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }

  logOut() {
    this.userService.logout();
  }
  confirmDeleteDialog(id: any) {
    this.userService.deleteUser(id).subscribe((res: any) => {
      this.toastr.success('User deleted successfully', 'Success', { timeOut: 2000 });
    });
  }


  getUserQuestionaries() {
    let uId = localStorage.getItem("candidateId");
    this.userService.getUserQuestionaries(uId).subscribe((res: any) => {
      if (res.length > 0) {
        this.questionary = res[0].status;
        if (this.isApplicant && this.questionary === 'DONE') {
          this.showReportMenu = true;
        } else {
          this.showReportMenu = false;
        }
      }
    });
  }
}



