import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/service/user.service';
import { environment } from 'enviroment/enviroment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  selectedLanguage:any = "en";
  forgetPasswordForm!: FormGroup;
  submitted!: boolean;
  errormsg: any;
  supportedLanguages: { flag: string,code: string, name: string}[] = [
    { flag: '../../../assets/english-flag.png',code: 'en' , name: 'English' },
    { flag: '../../../assets/german-flag.png' ,code: 'de', name: 'German', },
    { flag: '../../../assets/portuguese-flag.png', code: 'pt', name: 'Portuguese' },
    { flag: '../../../assets/frence-flag.png', code: 'fr',name: 'French', }
  ];
  constructor(private http: HttpClient, public _router: Router,
    private userService: UserService,
    private _translate: TranslateService,
    private toastr: ToastrService, private translate: TranslateService, private formBuilder: FormBuilder,) {
      translate.setDefaultLang('en');
      const savedLanguage = localStorage.getItem('selectedLanguage');
      if (savedLanguage) {
        this.selectedLanguage = savedLanguage;
        translate.use(savedLanguage);
      } else {
        translate.use('en');
      }
  }

 
  ngOnInit() {
    this.forgetPasswordForm = this.formBuilder.group({
      email: [
        null,
        [
          Validators.required,
          this.noWhitespaceValidator,
          Validators.pattern('^[aA-zZ0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      this.selectedLanguage = storedLanguage;
    } else {
      this.selectedLanguage = 'en';
      localStorage.setItem('selectedLanguage', 'en');
    }
    this.translate.use(this.selectedLanguage);
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('selectedLanguage', lang);
    this.userService.loadLang.next(lang);
    let lang1 = localStorage.getItem('selectedLanguage');
    this.userService.sendEmailResetPassword({
      email: this.forgetPasswordForm.controls['email'].value,
      lang: lang1 // Pass selected language here
    }).subscribe((res) => {
      this.userService.updateReset(res, lang1);
    });
  }
  
  getLanguageName(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.name : 'Language';
  }

  getLanguageFlag(code: string): string {
    const language = this.supportedLanguages.find(lang => lang.code === code);
    return language ? language.flag : '';
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgetPasswordForm.controls
  }

  noWhitespaceValidator(control: any) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  blurmsg() {
    this.errormsg = '';
  }

  sendEmailResetPassword() {
   const url: any = environment.ui + '/reset-password/change';
    this.submitted = true;
    let data: any = {
      email: this.forgetPasswordForm.controls['email'].value,
      uri: url,
    };
    this.userService.sendEmailResetPassword(data).subscribe(
      (response) => {
        this._router.navigate(['/login']);
        this.toastr.success('Reset-Password successfully');
      },
      (err) => {
         this.errormsg =
          'forgotpassword.emailNotExists';
          this.toastr.error('Invalid Email!');
      }
    );
  }
}
