import { Component } from '@angular/core';
import { CommonService } from 'app/service/common.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  showLoader = false;
  constructor(private _commonService: CommonService) {}

  ngOnInit(): void {
    this._commonService.loaderState$.subscribe((state: boolean) => {
      this.showLoader = state;
    });
  }
}
