<div class="modal-header">
  <h4 class="modal-title">{{ "Modal.chartDetail" | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body"> 
  <p>{{ "Modal.clickedValue" | translate }}: {{ clickedData }}</p>
  <p>{{ "Modal.sphereListName" | translate }}: {{ sphereListName }}</p>
  <p>{{ "Modal.legendName" | translate }}: {{ legendNames.join(' ,  ') }}</p>
  <ng-container *ngIf="textNames && textNames.length > 0">
    <p>{{ "Modal.Description" | translate }}: {{ textNames }}</p>
  </ng-container>
</div>

