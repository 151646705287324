import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { CommonService } from './service/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from './service/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private userService: UserService,
    public _commonService: CommonService,
    public cookieService: CookieService,
    private toastr: ToastrService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._commonService.show();
    const authToken = this.cookieService.get('authToken');
    if (authToken) {
      const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authToken) });
      if (req.url.includes('questionUpload')) {
        const modifiedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ') });
        return next.handle(modifiedReq);
      }
      return next.handle(authReq).pipe(
        catchError(error => {
          if (error.status === 403 || error.error?.detail === 'Token has expired') {
            this.handleTokenExpiration();
          }
          return throwError(error?.error?.detail || error?.detail);
        }),
        finalize(() => {
          this._commonService.hide();
        })
      );
    } else {
      this._commonService.hide();
      return next.handle(req);
    }
  }

  private handleTokenExpiration() {
    localStorage.clear();
    this.cookieService.deleteAll();
    this.toastr.error('Your session has expired. Please log in again.', 'Session Expired', { timeOut: 5000 });
    setTimeout(() => {
      this.router.navigate(['']);
      location.reload();
    }, 2000);
  }
}
