<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
  rel="stylesheet"
/>
<div class="inner-wrapperlogin">
  <div class="frontscreen-block">
    <div class="loging-header d-flex justify-content-between px-3">
      <div class="position-relative language-btn-block"></div>
      <div class="d-flex justify-content-between">
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <img [src]="getLanguageFlag(selectedLanguage)" class="selected-flag" />
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <span><img [src]="language.flag" /></span>
                {{ language.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 vh-100 align-items-center d-flex rounded-3">
          <div class="rightScreen login-card">
            <div class="logo-block pb-5">
              <a class="navbar-brand text-primary fw-bold fs-1"  href="javascript:void(0)">
                <img src="../../../assets/nynos-logo.svg" />
              </a>
              <h2 class="fs-2 fw-bold">{{"form.ResetPassword" | translate}}</h2>
            </div>
            <form class="row mt-5"  [formGroup]="forgetPasswordForm" (ngSubmit)="sendEmailResetPassword()">
              <div class="col-md-12 mb-3">
                <label>{{"form.email" | translate}}</label>
                <div class="form-block">
                  <input
                    type="mail"
                    class="form-control"
                    id="mail"
                    placeholder="{{
                      'form.email' | translate
                    }}"
                    formControlName="email"
                  />
                  <div *ngIf="
                  (submitted || f['email'].touched) &&
                  f['email'].hasError('required')
              " class="invalid-feedback">
                  {{
                    "login.form.email.error.required" | translate
                  }}
                  </div>
                  <div *ngIf="
                  submitted &&
                  f['email'].hasError('email')
              " class="invalid-feedback">
                   {{
                    "login.form.email.error.email" | translate
                  }}
                  </div>
                  <ng-container
                  *ngIf="errormsg"
                >
                <p class="form-error" *ngIf="errormsg && !forgetPasswordForm.get('email')?.errors?.['required']">
                  <span style="color: red">
                    {{ errormsg | translate}}</span>
                </p>
    
                </ng-container>
                </div>
              </div>
              <div class="col-12 text-center">
                <hr />
                <button type="submit" class="btn"> {{ "forgotPassword.form.send" | translate }}</button>
                <hr />
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100">
          <div class="leftScreen"></div>
        </div>
      </div>
    </div>
  </div>
</div>
