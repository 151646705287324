import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-line-chart-modal',
  templateUrl: './line-chart-modal.component.html',
  styleUrls: ['./line-chart-modal.component.scss']
})
export class LineChartModalComponent {
  @Input() clickedData: any;
  @Input() sphereListLabels: any;
  @Input() sphereListName: any;
  @Input() legendNames: any;
  @Input() selectedDescription: any;
  @Input() textNames: any;

  constructor(public modal: NgbActiveModal) { }
  ngOnInit() {
  }
  closeModal() {
    this.modal.close();
  }


}
