import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BootstrapService } from './service/bootstrap.service';
import { UserService } from './service/user.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userRole: any;
  constructor(private router: Router, private bootstrapService: BootstrapService, public userService: UserService,private cookieService: CookieService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.cookieService.get('authToken');
    if (!token) {
      this.router.navigate(['/login']);
      return false;
    }
    const requiredRoles = route.data['requiredRoles'];
    if (requiredRoles && requiredRoles.length > 0) {
      const userRole = this.cookieService.get('userRole') || '';
      if (requiredRoles.includes(userRole)) {
        return true;
      } else {
        const roleToRouteMap: { [key: string]: string } = {
          'ADMIN': '/admin',
          'MANAGER': '/invitations',
          'EMPLOYEE': '/profile',
          'APPLICANT': '/profile',
          'TRAINEE': '/profile'
        };
        const defaultRoute = roleToRouteMap[userRole] || '/account';
        this.router.navigate([defaultRoute]);
        return false;
      }
    }
    return true;
  }

}

