<link rel="preconnect" href="https://fonts.gstatic.com" />
<link
  href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap"
  rel="stylesheet"
/>
<div class="inner-wrapperlogin">
  <div class="frontscreen-block">
    <div class="loging-header d-flex justify-content-between px-3">
      <div class="position-relative language-btn-block"></div>
      <div class="d-flex justify-content-between">
        <div class="position-relative language-btn-block">
          <button
            class="language-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span>
              <img
                [src]="getLanguageFlag(selectedLanguage)"
                class="selected-flag"
              />
              {{ getLanguageName(selectedLanguage) }}
            </span>
          </button>
          <ul class="dropdown-menu">
            <li *ngFor="let language of supportedLanguages">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                (click)="changeLanguage(language.code)"
              >
                <span><img [src]="language.flag" /></span>
                {{ language.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-7 vh-100 align-items-center d-flex rounded-3">
          <div class="rightScreen login-card">
            <div class="logo-block">
              <a
                class="navbar-brand text-primary fw-bold fs-1"
                href="javascript:void(0)"
              >
                <img src="../../../assets/nynos-logo.svg" />
              </a>
              <h2 class="fs-2 fw-bold">{{ "invite.accept" | translate }}</h2>
            </div>
            <form class="row" [formGroup]="Createacceptform">
              <div class="col-md-12 mb-3">
                <label>{{ "form.email" | translate }}</label>
                <div class="form-block">
                  <input
                    type="text"
                    class="form-control txt"
                    formControlName="email"
                    placeholder="{{ 'form.email' | translate }}" readonly
                  />
                  <ng-container
                    *ngIf="
                      Createacceptform.get('email')?.invalid &&
                      Createacceptform.get('email')?.touched
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="Createacceptform.get('email')?.errors?.['required']"
                    >
                      <span style="color: red">{{
                        "admin.forms.email" | translate
                      }}</span>
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-3 col">
                    <label>{{ "form.firstname" | translate }}</label>
                    <div class="form-block">
                      <input
                        type="text"
                        class="form-control"
                        id="fname"
                        formControlName="firstName"
                        placeholder="{{ 'form.firstname' | translate }}"
                      />
                      <ng-container
                        *ngIf="
                          Createacceptform.get('firstName')?.invalid &&
                          Createacceptform.get('firstName')?.touched
                        "
                      >
                        <p class="caption status-danger">
                          <span style="color: red">
                            {{
                              "acceptform.form.firstname.error.required"
                                | translate
                            }}</span
                          >
                        </p>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3 col">
                    <label>{{ "form.lastname" | translate }}</label>
                    <div class="form-block">
                      <input
                        type="text"
                        class="form-control"
                        id="lname"
                        formControlName="lastName"
                        placeholder="{{ 'form.lastname' | translate }}"
                      />
                      <ng-container
                        *ngIf="
                          Createacceptform.get('lastName')?.invalid &&
                          Createacceptform.get('lastName')?.touched
                        "
                      >
                        <p
                          class="caption status-danger"
                          *ngIf="Createacceptform.get('lastName')?.errors?.['required']"
                        >
                          <span style="color: red">{{
                            "acceptform.form.lastname.error.required"
                              | translate
                          }}</span>
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mb-3">
                <label>{{ "form.password" | translate }}</label>
                <div class="form-block">
                  <input type="{{ newPassword ? 'text' : 'password' }}"
                    type="password"
                    class="form-control"
                    id="Password"
                    formControlName="password"
                    placeholder="{{ 'form.password' | translate }}"
                  />
                  <button type="button" class="btn btn-toggle" (click)="togglePasswordVisibility()">
                    <img [src]="newPassword ? '../../../assets/eye-slash.svg' : '../../../assets/eye.svg'" alt="Toggle Password Visibility" />
                </button>
              </div>

                 <ng-container
                    *ngIf="
                      Createacceptform.get('password')?.invalid &&
                      Createacceptform.get('password')?.touched
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="Createacceptform.get('password')?.errors?.['required']"
                    >
                      <span style="color: red">{{
                        "acceptform.form.pass.error.required" | translate
                      }}</span>
                    </p>
                  </ng-container>
                  <label class="mt-2">
                  <app-password-strength [passwordToCheck]="Createacceptform.value.password"></app-password-strength>
                </label>
              </div>
              <div class="col-md-12 mb-3">
                <label>{{ "form.repeatPassword" | translate }}</label>
                <div class="form-block">
                  <input
                  type="{{ repeatPassword ? 'text' : 'password' }}"
                    type="password"
                    class="form-control"
                    id="Confirm Password"
                    formControlName="repeatpass"
                    placeholder="{{ 'form.repeatPassword' | translate }}"
                  />
                  <button type="button" class="btn btn-toggle" (click)="repeatPasswordVisibility()">
                    <img [src]="repeatPassword ? '../../../assets/eye-slash.svg' : '../../../assets/eye.svg'" alt="Toggle Password Visibility" />
                </button>
              </div>
                  <ng-container
                    *ngIf="
                      Createacceptform.get('repeatpass')?.invalid &&
                      Createacceptform.get('repeatpass')?.touched
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="Createacceptform.get('repeatpass')?.errors?.['required']"
                    >
                      <span style="color: red">{{
                        "acceptform.form.repeatpass.error.required" | translate
                      }}</span>
                    </p>
                  </ng-container>
                
              </div>

              <div class="col-12 text-center">
                <hr />
                <button type="submit" (click)="join()" class="btn">
                  {{ "form.join" | translate }}
                </button>
                <hr />
              </div>
            </form>
          </div>
        </div>
        <div class="col-xl-5 d-none d-xl-block p-0 vh-100">
          <div class="leftScreen"></div>
        </div>
      </div>
    </div>
  </div>
</div>
